<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid" id="cj">
      <div class="row justify-content-center">
        <div class="col-12" data-aos="fade-right">
          <h3 class="title mt-3 mt-lg-5">{{ title }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="row" v-for="(item, key) in locale[lang].SUBMENU_JEUNES.ITEMS_LEFT" :key="key" @click.prevent="goTo(item)">
            <SubMenuItem :text="item.text" />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="row" v-for="(item, key) in locale[lang].SUBMENU_JEUNES.ITEMS_RIGHT" :key="key" @click.prevent="goTo(item)">
            <SubMenuItem :text="item.text" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
import SubMenuItem from "@/components/SubMenuItem.vue";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";

export default {
  components: {
    SubMenuItem,
    NavBar,
    Footer
  },
  methods: {
    goTo(next) {
      if (next.next || next.next == !"") {
        if (next.param) {
          this.$router.push({ name: next.next, params: { id: next.param } });
        } else {
          this.$router.push({ name: next.next });
        }
      }
    },
  },
  created() {
    var w = window.innerWidth;
    this.title = this.locale[this.lang].SUBMENU_JEUNES.TITLE;
    if (w <= 825) {
      this.title = this.locale[this.lang].SUBMENU_JEUNES.TITLE_MOBILE;
    }
  },
  data() {
    return {
      title: "",
    };
  },
};
</script>

<style scoped>
#cj {
  padding: 10px 140px 2% 140px !important;
  position: relative;
  background-image: url("../assets/Images/Background/Trazado 1903.png"),
    url("../assets/Images/Background/Grupo 1025.png"),
    url("../assets/Images/Background/Grupo 1027.png");
  background-repeat: no-repeat;
  background-size: auto, 276px 242px, auto;
  background-position: 20% 85%, 50% 100%, 5% 5%;
}

.title {
  text-align: center;
  font: normal normal 600 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #0e0e0e;
  opacity: 1;
}

.row.justify-content-center {
  margin-bottom: 100px;
}

.row.justify-content-center .col-12 {
  position: relative;
}

.row.justify-content-center .col-12::after {
  content: "";
  border-bottom: 3px solid #ff0313;
  width: 60px;
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
  margin: auto;
}

@media (max-width: 1024px) {
  #cj {
    background-size: auto, 276px 242px, 50%;
    padding: 0 0 0 35px !important;
    background-position: 20% 85%, 50% 100%, 5% 12%;
  }

  .title {
    text-align: center;
    font: normal normal 600 24px/29px Montserrat;
    margin-bottom: 50px;
  }

  .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row.justify-content-center {
    margin-bottom: 0;
  }

  .row.justify-content-center > .col-12 {
    padding-left: 0;
  }

  .row.justify-content-center .col-12::after {
    bottom: 30px;
  }

  #cj >>> .sm-item .container-fluid {
    padding: 20px 26px;
  }

  #cj >>> .sm-item h3 {
    font: normal normal bold 17px/20px Montserrat !important;
  }
}

@media (max-width: 425px) {
  .title {
    text-align: left;
  }

  .row {
    justify-content: flex-start;
  }

  .row.justify-content-center .col-12::after {
    margin: 0;
  }
}
</style>
