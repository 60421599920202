<template>
    <div class="sm-item">
        <div class="container-fluid">
            <h3>
                {{text}}
            </h3>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      text: String    
    }
}
</script>

<style scoped>
.sm-item {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #0000001A;
    border-radius: 15px;
    opacity: 1;
    margin-bottom: 30px;
    cursor: pointer;
    width: 92%;
}

.container-fluid {
    background-image: url('../assets/Images/Enmascarar grupo 163@2x.png');
    background-repeat: no-repeat;
    background-size: 20%;
    background-position: 100% 0%;
    display: flex;
    align-items: center;
    padding: 25px 41px;
}

h3 {
    text-align: left;
    font: normal normal bold 26px/32px Montserrat;
    letter-spacing: 0px;
    color: #080808;
    opacity: 1;
}

</style>